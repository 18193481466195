<template>
	<v-navigation-drawer
		:mini-variant-width="70"
		app
		:permanent="!isMobile"
		:expand-on-hover="!isMobile"
		v-model="isMenuOpen"
		:style="{ background: 'var(--main-color)', height: '100%' }"
	>
		<v-list>
			<div v-for="(menu, i) in menus" :key="'menu-professor-' + i">
				<list-item v-if="!menu.group" :item="menu" @modalAppAgenda="$emit('modalAppAgenda')" />

				<list-group-item @modalAppAgenda="$emit('modalAppAgenda')" v-else :menu="menu" />
			</div>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import ListItem from "./ListItem";
import ListGroupItem from "./ListGroupItem";

export default {
	name: "NavbarGestor",
	props: ["toggleMenu", "modalAppAgenda"],
	components: { ListItem, ListGroupItem },
	data() {
		return {
			isMenuOpen: false,
			menus: [
				{
					icon: require("@assets/icones/menu-escola.svg"),
					titulo: "Minha Escola",
					rota: "/gestor/minha-escola",
					ativo: false,
				},
				{
					icon: require("@assets/icones/menu-home.svg"),
					titulo: "Home",
					rota: "/gestor/home",
					ativo: false,
				},
				{
					titulo: "Comunicação",
					icon: require("@assets/icones/menu-comunicacao.png"),
					group: true,
					itens: [
						{
							icon: require("@assets/icones/menu-comunicado.png"),
							titulo: "Comunicados",
							rota: "/gestor/comunicados",
							ativo: false,
						},
						{
							icon: require("@assets/icones/menu-eventos.svg"),
							titulo: "Eventos",
							rota: "/gestor/agenda",
							ativo: false,
						},
						{
							icon: require("@assets/icones/menu-smartphone.png"),
							titulo: "App Agenda",
							ativo: false,
							rota: "modalAppAgenda",
						},
						{
							icon: require("@assets/icones/menu-boletim.png"),
							titulo: "Boletim",
							rota: "/gestor/cadastro/boletim",
							ativo: false,
						},
					],
				},
				{
					icon: require("@assets/icones/menu-configuracoes_de_acesso.svg"),
					titulo: "Configuração de Acesso",
					rota: "/gestor/configuracao-acesso",
					ativo: false,
				},
				{
					titulo: "Relatórios",
					icon: require("@assets/icones/relatorio.png"),
					group: true,
					itens: [
						{
							icon: require("@assets/icones/menu-acessos.png"),
							titulo: "Relatório de Acessos",
							rota: "/gestor/relatorio/acessos",
							ativo: false,
						},
						// {
						//   icon: require("@assets/icones/menu-relatorio.svg"),
						//   titulo: "Relatório de Avaliações",
						//   rota: "/gestor/relatorio/avaliacoes",
						//   ativo: false,
						// },
					],
				},

				{
					icon: require("@assets/icones/menu-marketing-educacional.png"),
					titulo: "Marketing Educacional",
					rota: "/gestor/marketing-educacional",
					ativo: false,
				},
				{
					icon: require("@assets/icones/menu-assessoria.svg"),
					titulo: "Assessoria Pedagógica",
					rota: "/professor-gestor/assessoria",
					ativo: false,
				},
				{
					icon: require("@assets/icones/menu-recursos-educacionais.svg"),
					titulo: "Recursos Educacionais Digitais",
					rota: "/professor-gestor/recursos-educacionais",
					ativo: false,
				},
				// {
				//   icon: require("@assets/icones/menu-turmas.svg"),
				//   titulo: "Minhas Turmas",
				//   rota: "/professor-gestor/minhas-turmas",
				//   ativo: false,
				// },
				{
					titulo: "Livros",
					icon: require("@assets/icones/menu-livro.svg"),
					rota: "/professor-gestor/livros-digitais",
					ativo: false,
				},
				{
					icon: require("@assets/icones/menu-album.svg"),
					titulo: "Álbuns de Fotos",
					rota: "/professor-gestor/albuns",
					ativo: false,
				},

				// {
				//   icon: require("@assets/icones/menu-sala.svg"),
				//   titulo: "Sala de Professores",
				//   rota: "/gestor/sala-professores",
				//   ativo: false,
				// },

				// {
				//   titulo: "Relatório",
				//   icon: require("@assets/icones/menu-relatorio.svg"),
				//   group: true,
				//   itens: [
				//     {
				//       icon: "",
				//       titulo: "Acessos",
				//       rota: "/gestor/relatorio/acessos",
				//       ativo: false
				//     },
				//     {
				//       icon: "",
				//       titulo: "Avaliações",
				//       rota: "/gestor/relatorio/acessos",
				//       ativo: false
				//     },
				//   ],
				// },

				{
					titulo: "Sair",
					icon: require("@assets/icones/menu-sair.svg"),
					// icon: Logout,
					rota: "sair",
				},
			],
		};
	},
	watch: {
		toggleMenu() {
			this.isMenuOpen = !this.isMenuOpen;
		},
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width <= 600;
		},
	},
	created() {
		this.isMenuOpen = this.menuOpen;
	},
};
</script>
