<template>
  <v-navigation-drawer
      :mini-variant-width="70"
      app
      :permanent="!isMobile"
      :expand-on-hover="!isMobile"
      v-model="isMenuOpen"
      :style="{ background: 'var(--main-color)', height: '100%' }"
  >
    <v-list>
      <div v-for="(menu, i) in menus" :key="'menu-professor-' + i">
        <list-item v-if="!menu.group" :item="menu"/>

        <list-group-item v-else :menu="menu"/>
      </div>
    </v-list>

    <!--      <v-list class="px-2" v-show="privilegio == 5">-->
    <!--        <v-list-item-->
    <!--            v-for="(menu, i) in menusConteudo"-->
    <!--            :key="'menu-conteudo-' + i"-->
    <!--            @click="clickAcaoMenu(menu)"-->
    <!--            class="white&#45;&#45;text"-->
    <!--            :class="{'lista&#45;&#45;ativa': rotaAtiva(menu.rota), 'mb-3': menu.titulo === 'Relatório das Questões'}"-->
    <!--        >-->
    <!--          <v-list-item-icon class="my-auto">-->
    <!--            &lt;!&ndash; <component :is="menu.icon" color="#fff" /> &ndash;&gt;-->
    <!--            <v-img max-width="30" max-height="30" :src="menu.icon"/>-->
    <!--            <div v-if="menu.titulo === 'Relatório das Questões'" class="divisor"></div>-->
    <!--          </v-list-item-icon>-->

    <!--          <v-list-item-title class="my-2" :style="{ whiteSpace: 'normal' }">-->
    <!--            {{ menu.titulo}}-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--      </v-list>-->
  </v-navigation-drawer>
</template>

<script>
import api from "@/api";
import ListItem from "./ListItem";
import ListGroupItem from "./ListGroupItem";

export default {
  name: "NavbarAcesso",
  props: ["toggleMenu"],
  components: {ListItem, ListGroupItem},
  data() {
    return {
      isMenuOpen: false,
      menus: [
        {
          titulo: "Assessoria pedagógica",
          icon: require("@assets/icones/menu-assessoria.svg"),
          group: true,
          itens: [
            {
              icon: require("@/assets/icones/menu-eventos.svg"),
              titulo: "Encontros Pedagógicos",
              rota: "/acesso/crud/encontros-pedagogicos",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/menu-mensagens.svg"),
              titulo: "Informações",
              rota: "/acesso/crud/informacoes",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/menu-livro.svg"),
              titulo: "Conteúdo de Apoio",
              rota: "/acesso/crud/conteudo-apoio",
              ativo: false,
            },
          ]
        },
         {
           icon: require("@assets/icones/menu-configuracoes_de_acesso.svg"),
           titulo: "Configuração de Acesso",
           rota: "/acesso/configuracao-acesso",
           ativo: false,
         },
        {
          titulo: "Relatório de Acessos",
          icon: require("@assets/icones/menu-acessos.png"),
          rota: "/acesso/relatorio-acesso",
          ativo: false,
        },
        {
          titulo: "Sair",
          icon: require("@assets/icones/menu-sair.svg"),
          // icon: Logout,
          rota: "sair",
        },
      ],
    };
  },
  watch: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 600;
    },
  },
  created() {
    this.isMenuOpen = this.menuOpen;
  },
}
;
</script>
