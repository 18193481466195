import store from "../store";
import api from "@/api";
export default async (to, from, next) => {
  let user = await store.dispatch("usuarioStore/loadUser");
  let idPrivilegio = to.meta.id_privilegio ? to.meta.id_privilegio : 0;

  let canAccessRoute = idPrivilegio && idPrivilegio.includes(user.id_privilegio);

  if (!user.id || !canAccessRoute) {
    next("/");
    return false;
  }

  next();
};
