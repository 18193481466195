<template>
  <v-list-item
    @click="clickAcaoMenu(item)"
    class="white--text"
    :class="{
      'lista--ativa': rotaAtiva(item.rota),
    }"
  >
    <v-list-item-icon class="d-flex align-center justify-center mx-0 my-2">
      <!-- <component :is="item.icon" color="#fff" /> -->
      <v-img contain max-width="30" max-height="30" :src="item.icon" />
    </v-list-item-icon>

    <v-list-item-title class="ms-5 my-0" :style="{ whiteSpace: 'normal' }">
      {{ item.titulo }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import api from "@/api";

import Swal from "sweetalert2";
export default {
  name: "ListItem",
  props: {
    item: { type: Object },
  },
  methods: {
    clickAcaoMenu(menu) {
      if (menu.rota == "sair") {
        this.sair();
      } else if (menu.rota == 'modalAppAgenda') {
          this.$emit('modalAppAgenda');
      } else if(menu.rota) {
        if(menu.rota != this.$route.fullPath){
          this.$router.push(menu.rota);
        }
      } else {
        this.alertWarning(menu.detalhesModal);
      }
    },

    rotaAtiva(rota) {
      return this.$route.fullPath.indexOf(rota) !== -1;
    },

    sair() {
      Swal.fire({
        title: "Tem certeza que deseja sair?",
        confirmButtonText: "Sair",
        showCancelButton: true,
        cancelButtonText: "Quero estudar",
        customClass: {
          title: "text-swal",
          cancelButton: "azul-swal",
          confirmButton: "vermelho-swal",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          api.post("/sair");
          this.$router.replace("/");
        }
      });
    },
  },
};
</script>

<style></style>
