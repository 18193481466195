import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import icones from "../icones";

Vue.use(Vuetify);

const theme = {
  // cores principais
  azul: "#0d47a1",
  roxo: "#4B089F",
  corSistema: "#E63B50",
};

const vuetify = new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      home: {
        component: "home",
      },
      grafico: {
        component: "grafico",
      },
      chapeu: {
        component: "chapeu",
      },
      play: {
        component: "play",
      },
      arquivo: {
        component: "arquivo",
      },
      interrogacao: {
        component: "interrogacao",
      },
      sair: {
        component: "sair",
      },
      cadeado: {
        component: "cadeado",
      },
      calendario: {
        component: "calendario",
      },
      setinha_direita: {
        component: "setinha_direita",
      },
      setinha_esquerda: {
        component: "setinha_esquerda",
      },
      bolinha: {
        component: "bolinha",
      },
    },
  },
});

export default vuetify;
