import auth from "@/middleware/auth";

let prefixo = "/professor-gestor";
const privilegios = [2, 6];

export default [
    //Recursos Educacionais
    {
        path: prefixo + "/recursos-educacionais",
        name: "recursos-educacionais",
        component: () => import("../pages/professor/recursosEducacionais/RecursosEducacionaisInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/disciplinas",
        name: "disciplinas",
        component: () => import("../pages/professor/recursosEducacionais/caminho/DisciplinasInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },

    {
        path: prefixo + "/recursos-educacionais/video/disciplina/:id_disciplina",
        name: "material-videos",
        component: () => import("../pages/professor/recursosEducacionais/videos/VideosInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },

    {
        path: prefixo + "/recursos-educacionais/:tipo/disciplina/:id_disciplina",
        name: "material-videos",
        component: () => import("../pages/professor/recursosEducacionais/caminho/AnosInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/material/:tipo/assistir",
        name: "videoaulas-assuntos",
        component: () => import("../pages/professor/recursosEducacionais/videoaulasAnimacoes/AssistirVideoAulas.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/videoaulas/disciplina/:id_disciplina/ano/:id_ano/assistir-videoaula/:id_video?",
        name: "assistir-videoaula",
        component: () => import("../pages/professor/recursosEducacionais/videoaulasAnimacoes/AssistirVideoAulas.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/infograficos-mapas",
        name: "infograficos-mapas",
        component: () =>
            import("../pages/professor/recursosEducacionais/infograficosMapas/InfograficosMapasInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/podcast",
        name: "podcast",
        component: () =>
            import("../pages/professor/recursosEducacionais/podcast/PodcastInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/audios-ingles",
        name: "podcast",
        component: () =>
            import("../pages/professor/recursosEducacionais/audiosIngles/AudiosInglesInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/audios-infantis",
        name: "podcast",
        component: () =>
            import("../pages/professor/recursosEducacionais/audiosInfantis/AudiosInfantisInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/planejamento",
        name: "planejamento-professor",
        component: () =>
            import("../pages/professor/recursosEducacionais/planejamento/PlanejamentoInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/sequencia/:id_disciplina",
        name: "material-sequencia-didatica",
        component: () =>
            import("../pages/professor/recursosEducacionais/sequenciaDidatica/SequenciaDidaticaInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
    {
        path: prefixo + "/recursos-educacionais/projeto-integrador/:id_disciplina",
        name: "projeto-integrador",
        component: () => import("../pages/professor/recursosEducacionais/projetosIntegradores/ProjetosIntegradoresInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },

    //Minhas Turmas
    {
        path: prefixo + "/minhas-turmas",
        name: "minhas-turmas",
        component: () => import("../pages/professor/salaAula/MinhasTurmasInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },

    // Livros Digitais
    {
        path: prefixo + "/livros-digitais",
        name: "prefixo-digitais",
        component: () => import("../pages/professor/livrosDigitais/Inicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },

    // Albuns de Fotos
    {
        path: prefixo + "/albuns",
        name: "albuns",
        component: () => import("../pages/professor/albuns/AlbunsInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },

    // Assessoria pedagógica
    {
        path: prefixo + "/assessoria",
        name: "assessoria-professor",
        component: () => import("../pages/professor/assessoriaPedagogica/AssessoriaInicio.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegios,
        },
    },
];
