let prefixoConteudo = "/conteudo";
const privilegioConteudo = [5];
import auth from "../middleware/auth";

export default [
	{
		path: prefixoConteudo + "/relatorio-questoes",
		name: "relatorio-questoes",
		component: () => import("../pages/conteudo/relatorioQuestoes/Inicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/atividade/adicionar-editar-questao/:id_questao?",
		name: "adicionar-editar-questoes-atividade",
		component: () => import("../pages/conteudo/relatorioQuestoes/AdicionarEditarQuestaoAtividade.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/avaliacao/adicionar-editar-questao/:id_questao?",
		name: "adicionar-editar-questoes-avaliacao",
		component: () => import("../pages/conteudo/relatorioQuestoes/AdicionarEditarQuestaoAvaliacao.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	//cruds reds

	{
		path: prefixoConteudo + "/crud/saeb",
		name: "Crud-Saeb",
		component: () => import("../pages/conteudo/crud/saeb/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	{
		path: prefixoConteudo + "/crud/bncc",
		name: "Crud-BNCC",
		component: () => import("../pages/conteudo/crud/bncc/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	{
		path: prefixoConteudo + "/crud/objeto-conhecimento",
		name: "Crud-Objeto-Conhecimento",
		component: () => import("../pages/conteudo/crud/objetoConhecimento/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	{
		path: prefixoConteudo + "/crud/habilidade",
		name: "Crud-Habilidade",
		component: () => import("../pages/conteudo/crud/habilidade/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/crud/banner-home",
		name: "Crud-BannerHome",
		component: () => import("../pages/conteudo/crud/bannerHome/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/crud/podcast",
		name: "Crud-Podcast",
		component: () => import("../pages/conteudo/crud/podcast/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/crud/audios-ingles",
		name: "Crud-Audio-ingles",
		component: () => import("../pages/conteudo/crud/audioIngles/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	{
		path: prefixoConteudo + "/crud/sequencia-didatica",
		name: "Crud-Sequencia-didatica",
		component: () => import("../pages/conteudo/crud/sequenciaDidatica/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
		props: {
			tipo: "sequencia",
		},
	},
	{
		path: prefixoConteudo + "/crud/planejamentos",
		name: "Crud-Planejamentos",
		component: () => import("../pages/conteudo/crud/sequenciaDidatica/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
		props: {
			tipo: "planejamento",
		},
	},
	{
		path: prefixoConteudo + "/crud/infograficos-mapas",
		name: "Crud-Infograficos",
		component: () => import("../pages/conteudo/crud/infograficosMapas/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/crud/videos",
		name: "Crud-Videos",
		component: () => import("../pages/conteudo/crud/videos/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	{
		path: prefixoConteudo + "/crud/videoaulas-animacoes",
		name: "Crud-Videoaulas",
		component: () => import("../pages/conteudo/crud/videoaulasAnimacoes/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/crud/projetos-integradores",
		name: "Crud-Projetos-Integradores",
		component: () => import("../pages/conteudo/crud/sequenciaDidatica/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
		props: {
			tipo: "projeto_integrador",
		},
	},

	{
		path: prefixoConteudo + "/crud/audios-ingles",
		name: "Crud-Audios-Ingles",
		component: () => import("../pages/conteudo/crud/podcast/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
		props: {
			tipo: "audio_ingles",
		},
	},
	{
		path: prefixoConteudo + "/crud/livros",
		name: "Crud-Livros",
		component: () => import("../pages/conteudo/crud/livro/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/crud/escolas",
		name: "Crud-Escolas",
		component: () => import("../pages/conteudo/crud/escola/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	//cruds assessoria

	{
		path: prefixoConteudo + "/crud/encontros-pedagogicos",
		name: "Crud-Encontros-Pedagogicos",
		component: () => import("../pages/conteudo/crud/encontrosPedagogicos/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
		props: {
			tipo: "encontro",
		},
	},
	{
		path: prefixoConteudo + "/crud/informacoes",
		name: "Crud-Informacoes",
		component: () => import("../pages/conteudo/crud/informacoes/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
		props: {
			tipo: "informacao",
		},
	},
	{
		path: prefixoConteudo + "/crud/conteudo-apoio",
		name: "Crud-Conteudo-Apoio",
		component: () => import("../pages/conteudo/crud/conteudoApoio/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	{
		path: prefixoConteudo + "/crud/marketing-educacional",
		name: "Crud-Conteudo-Apoio",
		component: () => import("../pages/conteudo/crud/marketingEducacional/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
];
