import routesProfessor from "./professor.js";
import routesAluno from "./aluno";
import routesConteudo from "./conteudo";
import routesAcesso from "./acesso";
import routesGestor from "./gestor";
import routesProfessorGestor from "./professorGestor";

import VueRouter from "vue-router";
import Vue from "vue";
//teste

const routesGeral = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/auth/login/Login"),
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("../pages/auth/login/Login"),
  },
  {
    path: "/login2",
    name: "Login2",
    component: () => import("../pages/auth/login/Login"),
  },
  {
    path: "/pdf-viewer",
    name: "PdfViewer",
    component: () => import("../pages/PdfViewer.vue"),
  },
  {
    path: "/cadastro-aluno",
    name: "cadastro-aluno",
    component: () => import("../pages/auth/CadastroAluno"),
  },
  {
    path: "/cadastro-professor",
    name: "cadastro-professor",
    component: () => import("../pages/auth/CadastroProfessor"),
  },
  {
    path: "/recuperar-senha",
    name: "recupera-senha",
    component: () => import("../pages/auth/recuperarSenha/RecuperarSenha"),
  },

  {
    path: "/politica-privacidade",
    name: "politica-privacidade",
    component: () => import("../pages/PoliticaPrivacidade"),
  },
  {
    path: "/literarios",
    name: "literarios",
    component: () => import("../pages/literarios/Inicio.vue"),
  },
];

let conjunto = routesGeral.concat(
  routesProfessorGestor,
  routesProfessor,
  routesAluno,
  routesConteudo,
  routesAcesso,
  routesGestor
);
Vue.use(VueRouter);

const router = new VueRouter({
  routes: conjunto,
  mode: "history",
});

export default router;
