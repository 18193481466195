let prefixoProfessor = "/professor";
const privilegioProfessor = [2];
import auth from "../../middleware/auth";

export default [
  {
    path: prefixoProfessor + "/seus-arquivos",
    name: "seus-arquivos",
    component: () => import("@/pages/professor/seusArquivos/SeusArquivosInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },

  {
    path: prefixoProfessor + "/verificar-criar-modelagem",
    name: "verificar-criar-modelagem",
    component: () => import("@/pages/professor/seusArquivos/ModalRedirecionarModelagem.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },

  //Minhas Avaliações
  {
    path: prefixoProfessor + "/seus-arquivos/avaliacoes",
    name: "seus-arquivos-atividades-avaliacoes",
    component: () => import("@/pages/professor/seusArquivos/avaliacoes/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  {
    path: prefixoProfessor + "/avaliacao/criar/:id_avaliacao?",
    name: "criar-avaliacao",
    component: () => import("@/pages/professor/seusArquivos/avaliacoes/criar/CriarInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  {
    path: prefixoProfessor + "/seus-arquivos/avaliacao/:id_avaliacao/acompanhamento",
    name: "acompanhamento-avaliacao",
    component: () => import("@/pages/professor/seusArquivos/avaliacoes/acompanhamento/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  {
    path: prefixoProfessor + "/seus-arquivos/avaliacao/:id_avaliacao/inserir-respostas",
    name: "inserir-respostas-avaliacao",
    component: () => import("@/pages/professor/seusArquivos/avaliacoes/InserirRespostas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  //Imprimir avaliacao
  {
    path: prefixoProfessor + "/seus-arquivos/avaliacoes/:id_avaliacao/imprimir",
    name: "imprimir-avaliacoes",
    component: () => import("@/pages/professor/seusArquivos/avaliacoes/imprimir/ImprimirInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },

  //Relatório Seus arquivos
  {
    path: prefixoProfessor + "/seus-arquivos/relatorios",
    name: "relatorios-bimestre",
    component: () => import("@/pages/professor/seusArquivos/relatorios/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
    // props: true,
  },
  {
    path: prefixoProfessor + "/seus-arquivos/relatorios/individual/aluno/:id_aluno/bimestre/:id_bimestre",
    name: "relatorios-bimestre",
    component: () => import("@/pages/professor/seusArquivos/relatorios/individual/Home.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
    props: (route) => ({ idAluno: route.query.id_aluno, idBimestre: route.query.id_bimestre }),
    // props: true,
  },
  {
    path: prefixoProfessor + "/seus-arquivos/relatorios/turma",
    name: "relatorios-bimestre",
    component: () => import("@/pages/professor/seusArquivos/relatorios/turma/Home.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
    // props: true,
  },
  {
    path: prefixoProfessor + "/atividades-avaliacoes",
    name: "atividades-avaliacoes",
    component: () => import("@/pages/professor/atividadesAvaliacoes/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },

  // Atividade
  {
    path: prefixoProfessor + "/seus-arquivos/atividades",
    name: "seus-arquivos-atividades-avaliacoes",
    component: () => import("@/pages/professor/seusArquivos/atividades/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  {
    path: prefixoProfessor + "/atividade/criar/:id_atividade?",
    name: "criar-atividade",
    component: () => import("@/pages/professor/seusArquivos/atividades/criar/CriarInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  {
    path: prefixoProfessor + "/seus-arquivos/atividades/:id_atividade/imprimir",
    name: "imprimir-atividade",
    component: () => import("@/pages/professor/seusArquivos/atividades/imprimir/ImprimirInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  {
    path: prefixoProfessor + "/seus-arquivos/atividades/:id_atividade/acompanhamento",
    name: "acompanhamento-atividade",
    component: () => import("@/pages/professor/seusArquivos/atividades/acompanhamento/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  // {
  //     path: prefixoProfessor + "/avaliacao/:id_avaliacao/acompanhamento",
  //     name: "acompanhamento-avaliacao",
  //     component: () => import("../pages/professor/atividadesAvaliacoes/avaliacao/acompanhamento/Inicio.vue"),
  // },
  // {
  //     path: prefixoProfessor + "/avaliacao/:id_avaliacao/inserir-respostas",
  //     name: "inserir-respostas-avaliacao",
  //     component: () => import("../pages/professor/atividadesAvaliacoes/avaliacao/InserirRespostas.vue"),
  // },

  // Seus arquivos - Avaliação sistema
  {
    path: prefixoProfessor + "/seus-arquivos/avaliacao-sistema/disciplinas",
    name: "avaliacao-sistema-disciplinas",
    component: () => import("@/pages/professor/seusArquivos/avaliacoesSistema/DisciplinasInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
  {
    path: prefixoProfessor + "/seus-arquivos/avaliacao-sistema/disciplina/:id_disciplina",
    name: "avaliacao-sistema",
    component: () => import("@/pages/professor/seusArquivos/avaliacoesSistema/AvaliacoesInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioProfessor },
  },
];
