let prefixoRota = "/aluno";
const privilegioAluno = [3];
import auth from "../middleware/auth";

export default [
  {
    path: prefixoRota + "/home",
    name: "home-aluno",

    component: () => import("../pages/aluno/home/Home.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/videoaulas",
    name: "videoaulas-aluno",
    component: () => import("../pages/aluno/videoaulas/HomeVideoaulas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/videoaulas/aula/:id_aula",
    name: "videoaulas-aula-aluno",
    component: () => import("../pages/aluno/videoaulas/Videoaulas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/avaliacoes",
    name: "avaliacoes-aluno-home",
    component: () => import("../pages/aluno/avaliacoes/DisciplinasInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: { pagina: "avaliacoes" },
  },

  {
    path: prefixoRota + "/avaliacoes/disciplina/:id_disciplina",
    name: "avaliacoes-aluno-disciplina",
    component: () => import("../pages/aluno/avaliacoes/HomeAvaliacoes.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: true,
  },

  {
    path: prefixoRota + "/avaliacoes/avaliacao/:id_avaliacao/gabarito",
    name: "avaliacoes-gabarito-aluno",
    component: () => import("../pages/aluno/avaliacoes/GabaritoAvaliacao.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/avaliacoes/:id_avaliacao/responder",
    name: "avaliacoes-responder-aluno",
    component: () => import("../pages/aluno/avaliacoes/ResponderAvaliacao.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/albuns",
    name: "albuns",
    component: () => import("../pages/aluno/albuns/AlbunsInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/avaliacoes/:id_avaliacao/visualizar-respostas",
    name: "avaliacoes-aluno-respostas",
    component: () =>
      import("../pages/aluno/avaliacoes/VisualizarRespostas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/perfil",
    name: "perfil-aluno",
    component: () => import("../pages/aluno/perfil/HomePerfil.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },

  {
    path: prefixoRota + "/disciplinas",
    name: "disciplinas-inicio",
    component: () => import("../pages/aluno/disciplinas/DisciplinasInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: { pagina: "disciplinas" },
  },

  {
    path: prefixoRota + "/disciplinas/:idDisciplina/assistir-video",
    name: "assistir-video-disciplina",
    component: () => import("../pages/aluno/unidade/AssistirVideo.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: true,
  },
  {
    path: prefixoRota + "/mensagens",
    name: "mensagens-aluno",
    component: () => import("../pages/aluno/mensagens/MensagensInicioNova.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/mural",
    name: "mural-aluno",
    component: () => import("../pages/aluno/mural/MuralInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/livros-digitais",
    name: "livros-digitais-aluno",
    component: () => import("../pages/aluno/livrosDigitais/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/agenda",
    name: "agenda-aluno",
    component: () => import("../pages/aluno/agenda/Agenda.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/acompanhamento",
    name: "acompanhamento",
    component: () =>
      import("../pages/aluno/acompanhamento/AcompanhamentoInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },

    //Atividades
  {
    path: prefixoRota + "/atividades/disciplina/:id_disciplina",
    name: "atividades-aluno-disciplina",
    component: () => import("../pages/aluno/atividades/HomeAtividades.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: true,
  },
  {
    path: prefixoRota + "/atividades/:id_atividade/responder",
    name: "atividades-responder-aluno",
    component: () => import("../pages/aluno/atividades/ResponderAtividade.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/atividades/:id_atividade/visualizar-respostas",
    name: "atividades-aluno-respostas",
    component: () =>
        import("../pages/aluno/atividades/VisualizarRespostas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + '/issuu',
    name: 'LivrosEmbedIssuuAluno',
    ttl: 'LivrosEmbedIssuuAluno',
    icon: 'mdi-home-outline',
    meta: {
      id_privilegio: privilegioAluno,
    },
    component: () => import('../pages/aluno/LivrosEmbedIssuu.vue'),
    menu: false,
  },
  // {
  //   path: prefixoRota + "/atividades/atividade/:id_atividade/gabarito",
  //   name: "atividades-gabarito-aluno",
  //   component: () => import("../pages/aluno/atividades/GabaritoAtividade.vue"),
  //   beforeEnter: auth,
  //   meta: { id_privilegio: privilegioAluno },
  // },
];
