let prefixoGestor = "/gestor";
const privilegioGestor = [6];
import auth from "../middleware/auth";

export default [
  {
    path: prefixoGestor + "/home",
    name: "gestor-home",
    component: () => import("../pages/gestor/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/perfil",
    name: "gestor-perfil",
    component: () => import("../pages/gestor/Perfil.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  //SALA DE AULA
  {
    path: prefixoGestor + "/sala-aula/escola/:id_escola/turma/:id_turma",
    name: "sala-aula",
    component: () => import("../pages/gestor/salaAula/MainSalaAula.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  //SALA DOS PROFESSORES
  {
    path: prefixoGestor + "/sala-professores",
    name: "sala-professores",
    component: () => import("../pages/gestor/salaProfessores/SalaProfessoresInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/agenda",
    name: "gestor-agenda",
    component: () => import("../pages/gestor/agenda/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/cadastro/boletim",
    name: "gestor-cadastro-professor",
    component: () => import("../pages/gestor/cadastro/boletim/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/minha-escola",
    name: "gestor-cadastro-escola",
    component: () => import("../pages/gestor/minhaEscola/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/relatorio/acessos",
    name: "gestor-relatorio-acesso",
    component: () => import("../pages/gestor/relatorio/acessos/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/relatorio/avaliacoes",
    name: "gestor-relatorio-acesso",
    component: () => import("../pages/gestor/relatorio/avaliacao/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/configuracao-acesso",
    name: "gestor-configuracao-acesso",
    component: () => import("../pages/gestor/cadastro/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/marketing-educacional",
    name: "marketing-pecas-comunicacao",
    component: () => import("../pages/gestor/pecasComunicacao/PecasComunicacaoInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/comunicados",
    name: "marketing-pecas-comunicacao",
    component: () => import("../pages/gestor/comunicados/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + '/issuu',
    name: 'LivrosEmbedIssuuGestor',
    ttl: 'LivrosEmbedIssuuGestor',
    icon: 'mdi-home-outline',
    meta: {
      id_privilegio: privilegioGestor,
    },
    component: () => import('../pages/gestor/LivrosEmbedIssuu.vue'),
    menu: false,
  },
];
