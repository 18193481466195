import Vue from "vue";
import VuePlyr from "vue-plyr";
import App from "./components/app/App";
import router from "./routes/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import { Settings } from "luxon";
import "vue-plyr/dist/vue-plyr.css";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

// import Fragment from "vue-fragment";
import VueDragscroll from "vue-dragscroll";

import AOS from "aos";
import "aos/dist/aos.css";

window.Echo = new Echo({
	broadcaster: "pusher",
	key: "myappkey",
	wsHost: process.env.VUE_APP_WEBSOCKET,
	wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
	wssPort: process.env.VUE_APP_WEBSOCKET_SSL_PORT,
	forceTLS: true,
	disableStats: true,
});

Vue.config.productionTip = false;

Vue.use(VueDragscroll);

// Vue.use(Fragment.Plugin);
Vue.use(VuePlyr, {
	plyr: {},
});
Vue.directive("mask", VueMaskDirective);
Settings.defaultLocale = "pt-br";

//antes de aparecer a tela, acionado o loading global
//router.beforeEach((to, from, next) => {
	//loading global, deve ser utilizado os lugares, de forma minunciosa
	//store.commit('loadingStore/SET_LOADING', true)
	//next()
//})

//depois quando mostrar a tela, loading global desativado

//router.afterEach(() => {
	//loading global, deve ser utilizado os lugares, de forma minunciosa
	//store.commit('loadingStore/SET_LOADING', false)
//})

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
	mounted() {
		AOS.init();
	},
}).$mount("#app");
