<template>
  <v-navigation-drawer
      :mini-variant-width="70"
      :permanent="!isMobile"
      :expand-on-hover="!isMobile"
      v-model="isMenuOpen" app
      :style="{ background: 'var(--main-color)', height: '100%' }"
  >
    <v-list class="px-2">
      <v-list-item
          v-for="(menu, i) in menus"
          :key="'menu-professor-' + i"
          @click="onClickFunction(menu)"
          class="white--text"
          :class="{'lista--ativa': rotaAtiva(menu.rota), 'mb-3': menu.titulo === 'Livros', 'menu-meu-perfil' : menu.titulo === 'Meu Perfil'}"
      >
        <v-list-item-icon class="d-flex align-center justify-center mx-0 my-2">
          <v-img max-width="30" max-height="30" :src="renderIcon(menu.icon)"/>
          <div v-if="menu.titulo === 'Livros'" class="divisor"/>
        </v-list-item-icon>

        <v-list-item-title class="ms-5" :style="{ whiteSpace: 'normal' }">
          {{ menu.titulo }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <!--      <v-list class="px-2" v-show="privilegio == 5">-->
    <!--        <v-list-item-->
    <!--            v-for="(menu, i) in menusConteudo"-->
    <!--            :key="'menu-conteudo-' + i"-->
    <!--            @click="clickAcaoMenu(menu)"-->
    <!--            class="white&#45;&#45;text"-->
    <!--            :class="{'lista&#45;&#45;ativa': rotaAtiva(menu.rota), 'mb-3': menu.titulo === 'Relatório das Questões'}"-->
    <!--        >-->
    <!--          <v-list-item-icon class="my-auto">-->
    <!--            &lt;!&ndash; <component :is="menu.icon" color="#fff" /> &ndash;&gt;-->
    <!--            <v-img max-width="30" max-height="30" :src="menu.icon"/>-->
    <!--            <div v-if="menu.titulo === 'Relatório das Questões'" class="divisor"></div>-->
    <!--          </v-list-item-icon>-->

    <!--          <v-list-item-title class="my-2" :style="{ whiteSpace: 'normal' }">-->
    <!--            {{ menu.titulo}}-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--      </v-list>-->

    <ModalSair
        :modal-aberto="modalSairAberto"
        @close-modal="modalSairAberto = false"
        @sair="modalSairAberto = false; $router.push('/sair');"
        @continuar="modalSairAberto = false"
    />
  </v-navigation-drawer>
</template>

<script>
import alerts from "@/alerts";
import ModalSair from "@/components/ModalSair.vue";

export default {
  name: "NavbarProfessor",
  props: ["toggleMenu", 'modalAppAgenda', 'user'],
  mixins: [alerts],
  data() {
    return {
      isMenuOpen: false,
      modalSairAberto: false,

      menus: [
        // {
        //   titulo: "Início",
        //   // icon: House,
        //   icon: require("@assets/icones/menu-home.svg"),
        //   rota: "/professor/home",
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: SchoolBag,
        //   icon: require("@assets/icones/menu-recursos-educacionais.svg"),
        //   titulo: "Recursos Educacionais Digitais",
        //   texto:
        //       "Confira, nesta área, as aulas disponíveis de acordo com cada componente curricular e por ano.",
        //   rota: "/professor/recursos-educacionais",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: Reader,
        //   icon: require("@assets/icones/menu-turmas.svg"),
        //   titulo: "Minhas Turmas",
        //   texto:
        //       "Verifique os componentes de todas as suas turmas das escolas em que você leciona.",
        //   rota: "/professor/minhas-turmas",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: AddNewFile,
        //   icon: require("@assets/icones/menu-banco-itens.svg"),
        //   titulo: "Banco de Itens de Avaliações",
        //   texto: "Encontre questões variadas para aplicar para os alunos das suas turmas.",
        //   detalhesModal: "Funcionalidade em desenvolvimento",
        //   ativo: false,
        // },
        // {
        //   // icon: Brain,
        //   icon: require("@assets/icones/menu-modelagem.svg"),
        //   titulo: "Modelagem de Avaliações e Atividades",
        //   texto:
        //       "Confira as avaliações diagnósticas e formativas disponíveis para cada ano das suas respectivas turmas.",
        //   rota: "/professor/verificar-criar-modelagem",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: Book,
        //   titulo: "Meus Arquivos",
        //   icon: require("@assets/icones/menu-arquivo.svg"),
        //   // texto: "Clique para ver detalhes.",
        //   // detalhesModal: "Funcionalidade em desenvolvimento",
        //   rota: "/professor/seus-arquivos",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: Book,
        //   titulo: "Livros",
        //   icon: require("@assets/icones/menu-livro.svg"),
        //   // texto: "Clique para ver detalhes.",
        //   // detalhesModal: "Funcionalidade em desenvolvimento",
        //   rota: "/professor/livros-digitais",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: Camera,
        //   icon: require("@assets/icones/menu-album.svg"),
        //   titulo: "Álbuns de Fotos",
        //   texto:
        //       "Fique de olho nos eventos da sua escola e os registre aqui para que todos da turma vejam seus momentos.",
        //   rota: "/professor/albuns",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: Mortarboard,
        //   icon: require("@assets/icones/menu-assessoria.svg"),
        //   titulo: "Assessoria Pedagógica",
        //   texto:
        //       "Visualize aqui todas as formações ofertadas para os professores da plataforma.",
        //   rota: "/professor/assessoria",
        //   // detalhesModal: "Funcionalidade em desenvolvimento",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // {
        //   // icon: Bag,
        //   icon: require("@assets/icones/menu-sala.svg"),
        //   titulo: "Sala de Professores",
        //   texto: "Clique para ver detalhes.",
        //   rota: "/professor/sala-professores",
        //   ativo: false,
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        // // {
        // //   // icon: Computer,
        // //   icon: require("@assets/icones/menu-blog.svg"),
        // //   titulo: "Blog",
        // //   texto:
        // //     "Acesse aqui as notícias, textos de apoio e muito mais conteúdos do seu interesse!",
        // //   rota: "/professor/blog",
        // //   ativo: true,
        // // },
        // {
        //   icon: require("@assets/icones/menu-smartphone.png"),
        //   titulo: "App Agenda",
        //   ativo: false,
        //   onClickFunction: () => {
        //     this.$emit('modalAppAgenda');
        //   },
        // },
        // {
        //   titulo: "Meu Perfil",
        //   icon: require("@assets/icones/menu-perfil.svg"),
        //   // icon: Profile,
        //   rota: "/professor/perfil",
        //   onClickFunction: (item) => {
        //     this.clickAcaoMenu(item);
        //   },
        // },
        {
          titulo: "Sair",
          icon: "/assets/icones/aluno/menu-sair.svg",
          // icon: Logout,
          rota: "sair",
          onClickFunction: () => {
            this.sair();
          },
        },
      ],
    };
  },
  methods: {
    rotaAtiva(rota) {
      return this.$route.fullPath.indexOf(rota) !== -1;
    },
    renderIcon(icon) {
      return `${icon}`;
    },
    onClickFunction(menu) {
      if (menu.onClickFunction) {
        menu.onClickFunction();
      } else {
        this.clickAcaoMenu(menu);
      }
    },
    clickAcaoMenu(menu) {
      if (menu.rota != "#") {
        this.$router.push(menu.rota);
      } else {
        this.modalPaginaConstrucaoAberto = true;
      }
    },

    sair() {
      this.modalSairAberto = true;
    },
  },
  watch: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 600;
    },
  },
  created() {
    this.isMenuOpen = this.menuOpen;
    this.menus = this.user.modulos.concat(this.menus);
  },
  components: {ModalSair}
};
</script>

<style scoped lang="scss"></style>
