import api from "../api";
export default {
  namespaced: true,

  state: {
    notificacoes: [],
  },

  getters: {
    notificacoes(state) {
      return state.notificacoes;
    },
  },

  mutations: {
    //payload é um valor que é passado como parametro para alterar o state
    SET_NOTIFICATION(state, payload) {
      state.notificacoes = payload;
    },
  },

  actions: {
    //context é  como se tem acesso ao store nas actions
    loadNotifications: async function (context, payload) {
      try {
        let params = payload || "?page=1";
        let response = await api.get(
          "/geral/get-notificacoes-usuario" + params
        );
        let dados = response.data;
        context.commit("SET_NOTIFICATION", dados);
      } catch (err) {
        context.commit("SET_NOTIFICATION", []);
      }
    },
  },
};
