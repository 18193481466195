<template>
  <v-dialog v-model="getIsModalOpen" persistent max-width="499">
    <div class="modal card-aluno-default position-relative">
      <div class="d-flex justify-end" v-if="!hideFechar">
        <v-btn
          @click="$emit('close-modal')"
          class="ms-3"
          small
          fab
          color="#CE0000"
        >
          <v-icon color="#fff"> mdi-close </v-icon>
        </v-btn>
      </div>
      <div class="modal__content">
        <img height="400px" :src="imagem" alt="" />
        <p class="modal__descricao">{{ descricao }}</p>
      </div>

      <div class="modal__buttons">
        <slot name="buttons"></slot>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalPadrao",
  emits: ["closeModal"],
  props: {
    imagem: {
      required: true,
      type: String,
    },
    hideFechar: {
      type: Boolean,
    },
    descricao: {
      required: true,
      type: String,
    },
    modalAberto: {
      type: Boolean,
      validator: (value) => value === true || value === false,
    },
  },
  methods: {
    closeModal() {
      this.funcCloseModal();
    },
  },
  computed: {
    getIsModalOpen() {
      return this.modalAberto;
    },
  },

  mounted() {},
};
</script>
<style scoped lang="scss">
.modal {
  background-color: #fff;
  padding: 10px;
  padding-bottom: 30px;

  &__content {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__descricao {
    font-size: 1.2rem;
    font-weight: bold;
    color: #4f4f4f;
    margin-top: 10px;
  }

  .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 15px;
    background-color: transparent;
    border: 1px solid #4f4f4f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
